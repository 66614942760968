import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/Auth/LoginView.vue'
import AdminHome from '../views/Admin/Dashboard.vue'
import Account from '../views/Admin/Account.vue'
import store from '@/store/index'

const requireAuth = (to, from, next) => {
  console.log('Require Auth -->', store.getters.isAuthenticated)
  if (store.getters.isAuthenticated) {
    next();
  } else {
    next('/login');
  }
}

const requireNoAuth = (to, from, next) => {
  console.log('No Auth Required --> ', store.getters.isAuthenticated)
  if (store.getters.isAuthenticated) {
    next('/home');
  } else {
    next();
  }
}

const routes = [
  {
    path: '/home',
    name: 'home',
    component: AdminHome,
    beforeEnter: requireAuth,
  },
  {
    path: '/my-account',
    name: 'my-account',
    component: Account,
    beforeEnter: requireAuth,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    beforeEnter: requireNoAuth,
  },
  {
    path: '/',
    redirect: '/login'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
