<template>
  <div>
    <Navbar></Navbar>w

    <!-- Customer Modal -->
    <CustomerModal
      :showModal="showCustomerModal"
      :customersList="customersList"
      @close="closeCustomerModal"
      @save="saveCustomer"
    ></CustomerModal>

    <!-- Group Modal -->
    <GroupModal
      :showModal="showGroupModal"
      :customersList="customersList"
      @close="closeGroupModal"
      @save="saveCustomer"
    ></GroupModal>

    <!-- Container box with title and table -->
    <section class="section">
      <div class="container">
        <div class="box">
          <div class="level-left">
            <h1 class="title level-item">Customer Accounts</h1>
          </div>
          <div class="level-right">
            <button class="button is-primary level-item" @click="addGroup">
              Add Group
            </button>
            <button class="button is-primary level-item" @click="addCustomer">
              Add Customer
            </button>
          </div>
          <table class="table is-fullwidth is-hoverable">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Accounts Assigned</th>
                <th>Account Type</th>
                <!-- Add more columns as needed -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users" :key="user.id">
                <td>{{ user.first_name }} {{ user.last_name }}</td>
                <td>{{ user.email }}</td>
                <td>{{ user.role }}</td>
                <td v-if="user.account_type == 'CUSTOMER'">
                  <span
                    v-for="customer in user.customers"
                    :key="customer.customer_id"
                  >
                    <p>{{ customer.customer_name }}</p>
                  </span>
                </td>
                <td v-if="user.account_type == 'SUPPLIER'">
                  <span v-for="group in user.group" :key="group.group_id">
                    <p>{{ group.group_name }}</p>
                  </span>
                </td>
                <td>{{ user.account_type }}</td>
                <!-- Add more cells as needed -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>

    <Footer></Footer>
  </div>
</template>

<script>
import { onUnmounted, ref } from "vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import CustomerModal from "@/components/CustomerModal.vue";
import GroupModal from "@/components/GroupModal.vue";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "@/firebase/config";
import { useStore } from "vuex";

export default {
  components: { Navbar, CustomerModal, Footer, GroupModal },
  setup() {
    const users = ref([]);
    const showCustomerModal = ref(false);
    const showGroupModal = ref(false);
    const customersList = ref(["Role1", "Role2", "Role3"]);
    const groupList = ref(["Group1", "Group2", "Group3"]);
    let colRef = collection(db, "Users");
    const store = useStore();

    let unsubscribe = null;

    unsubscribe = onSnapshot(colRef, (snapshot) => {
      let results = [];

      snapshot.docs.forEach((doc) => {
        if (doc.data().role != "SUPER_ADMIN") {
          results.push({ ...doc.data(), id: doc.id });
        }
      });

      users.value = results;
      console.log(results);
    });

    onUnmounted(() => {
      if (unsubscribe) {
        unsubscribe();
      }
    });

    const addCustomer = () => {
      showCustomerModal.value = true;
    };

    const addGroup = () => {
      showGroupModal.value = true;
    };

    const closeCustomerModal = () => {
      showCustomerModal.value = false;
    };

    const closeGroupModal = () => {
      showGroupModal.value = false;
    };

    async function fetchCustomers() {
      const apiURL = store.state.apiUrl + "getCustomersByCountryId/190";

      try {
        const response = await fetch(apiURL);
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
        const data = await response.json();
        return data[1].customers;
      } catch (error) {
        console.error("Error fetching customers:", error);
        return [];
      }
    }

    async function addCustomerFunc(customerData) {
      const apiUrl = store.state.apiUrl + "create_admin_account/"; // Replace with your API URL

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(customerData),
        });
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
        return await response.json();
      } catch (error) {
        console.error("Error adding customer:", error);
      }
    }

    const saveCustomer = async (customer) => {
      console.log(customer);
      addCustomerFunc(customer);
      // users.value.push(customer);
    };

    fetchCustomers().then((customers) => {
      customersList.value = customers;
      // console.log("Customers:", customers);
    });

    return {
      saveCustomer,
      users,
      showCustomerModal,
      customersList,
      addCustomer,
      closeCustomerModal,
      addGroup,
      closeGroupModal,
      showGroupModal,
    };
  },
};
</script>

<style>
/* Additional styling for the home page */
</style>
