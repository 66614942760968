<template>
  <navbar></navbar>

  <section class="section">
    <div class="container">
      <div class="box">
        <div class="level-left">
          <h1 class="title level-item">My Account Details</h1>
        </div>
        <div class="level-right">
          <button class="button is-primary level-item">Edit Account</button>
        </div>
        <!-- TODO: Add the my account details -->
      </div>
    </div>
  </section>

  <Footer></Footer>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: { Navbar, Footer },
};
</script>

<style>
</style>