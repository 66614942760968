<template>
  <div class="modal" :class="{ 'is-active': showModal }">
    <div class="modal-background" @click="closeModal"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Create Group Account</p>
        <button class="delete" aria-label="close" @click="closeModal"></button>
      </header>
      <section class="modal-card-body">
        <form @submit.prevent="submitForm">
          <div class="field">
            <label class="label">First Name</label>
            <input class="input" type="text" v-model="first_name" required />
          </div>
          <div class="field">
            <label class="label">Last Name</label>
            <input class="input" type="text" v-model="last_name" required />
          </div>
          <div class="field">
            <label class="label">Email</label>
            <input class="input" type="email" v-model="email" required />
          </div>
          <!-- <div class="field">
              <label class="label">Password</label>
              <input class="input" type="password" v-model="password" required />
            </div> -->
          <div class="field">
            <label class="label">Contact Number</label>
            <input class="input" type="tel" v-model="contact_number" required />
          </div>
          <div class="field">
            <label class="label">Site</label>
            <div class="select">
              <select v-model="selectedSite" required>
                <option
                  v-for="site in sites"
                  :key="site.siteId"
                  :value="site.siteId"
                >
                  {{ site.siteName }}
                </option>
              </select>
            </div>
          </div>
          <div class="field">
            <label class="label">Group Name</label>
            <div class="select">
              <select v-model="selectedGroup" required>
                <option
                  v-for="group in groupsList"
                  :key="group.GroupId"
                  :value="{
                    group_id: group.GroupId,
                    group_name: group.GroupName,
                    site_name: group.SiteName,
                  }"
                >
                  {{ group.GroupName }}
                </option>
              </select>
            </div>
          </div>
          <footer class="modal-card-foot">
            <button type="submit" class="button is-success">Save</button>
            <button type="button" class="button" @click="closeModal">
              Cancel
            </button>
          </footer>
        </form>
      </section>
    </div>
  </div>
</template>
    
  <script setup>
import { ref, defineProps, defineEmits, watchEffect } from "vue";
import { useStore } from "vuex";
const { showModal, customersList } = defineProps([
  "showModal",
  "customersList",
]);
const emit = defineEmits(["close", "save"]);
const store = useStore();
const first_name = ref("");
const last_name = ref("");
const email = ref("");
const contact_number = ref("");
const selectedGroup = ref("");
const groupsList = ref([]);

const selectedSite = ref("FA669BD0-42F8-4EDF-8847-9ACD191759F1");
const sites = ref([
  { siteName: "Dubai", siteId: "FA669BD0-42F8-4EDF-8847-9ACD191759F1" },
  { siteName: "Abu Dhabi", siteId: "A8B87580-1745-408A-A9FB-0D27E4F7CE2D" },
]);

const closeModal = () => {
  emit("close");
};

async function getGroups(siteId) {
  const apiURL = store.state.apiUrl + "getGroupsBySite/" + siteId;
  try {
    const response = await fetch(apiURL);
    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    const data = await response.json();
    return data[1].groups;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return [];
  }
}

watchEffect(() => {
  getGroups(selectedSite.value).then((groups) => {
    groupsList.value = groups;
  });
});

const submitForm = () => {
  const customer = {
    first_name: first_name.value,
    last_name: last_name.value,
    email: email.value,
    customer_logo: "URL",
    // password: password.value,
    contact_number: contact_number.value,
    primary_account: selectedGroup.value.group_id,
    role: "ACCOUNT_ADMIN",
    group: selectedGroup.value
      ? [
          {
            group_id: selectedGroup.value.group_id,
            group_name: selectedGroup.value.group_name,
            site_name: selectedGroup.value.site_name,
          },
        ]
      : [],
    account_type: "SUPPLIER",
  };
  emit("save", customer);

  // Clear the form variables
  first_name.value = "";
  last_name.value = "";
  email.value = "";
  // password.value = "";
  contact_number.value = "";
  selectedGroup.value = null;

  closeModal();
};
</script>
    
    <style>
/* Additional styling if needed */
</style>
    