<template>
  <nav
    class="navbar is-fixed-top"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <a class="navbar-item" href="">
        <img src="../assets/earth.png" alt="Company Logo" />
        <h1 class="ml-2"><b>Company Portal Admin Dashboard</b></h1>
      </a>

      <!-- Hamburger menu -->
      <a
        role="button"
        class="navbar-burger burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbar-menu"
        @click="toggleMenu"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div class="navbar-menu" :class="{ 'is-active': menuActive }">
      <div class="navbar-start">
        <router-link to="/home" class="navbar-item" active-class="is-active"
          >Dashboard</router-link
        >
        <router-link
          to="/my-account"
          class="navbar-item"
          active-class="is-active"
          >My Account</router-link
        >
        <router-link to="/messages" class="navbar-item" active-class="is-active"
          >Messages</router-link
        >
      </div>

      <div v-if="userProfile" class="navbar-end">
        <div class="navbar-item">
          Curently Logged In as {{ userProfile.firstName }} (<span
            class="is-danger"
            ><b>{{ userProfile.role }}</b></span
          >)
        </div>
        <div class="navbar-item">
          <button class="button is-danger is-rounded is-small" @click="logout">
            Logout
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>
  
  <script>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const menuActive = ref(false); // State to track whether the menu is open

    const logout = async () => {
      await store.dispatch("logout");
      router.push({ name: "login" });
    };

    const toggleMenu = () => {
      menuActive.value = !menuActive.value; // Toggle the menu
    };

    return {
      logout,
      menuActive,
      toggleMenu,
      userProfile: computed(() => store.state.userProfile),
    };
  },
};
</script>
  
  <style>
/* Style for the active navbar item */
.navbar-item.is-active {
  background-color: #6289ff;
}
</style>
  