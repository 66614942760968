import { createStore } from 'vuex'

// firebase imports
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import {
  doc,
  onSnapshot
} from "firebase/firestore";
import { auth, db } from "@/firebase/config";
import { watchEffect } from "vue";

const store = createStore({
  state: {
    user: null,
    userProfile: null,
    authIsReady: false,
    currentRoute: null,
    versionNumber: 'V 2.0.0',
    apiUrl: 'https://customer-portal-api.neutralfuels.net/' // PROD ENV
    // apiUrl: 'http://localhost:3100/' // TEST ENV
    // apiUrl: 'http://127.0.0.1:8000/' // Uvicron ENV

  },
  getters: {
    isAuthenticated: state => !!state.user && state.authIsReady,
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload
      console.log('User state changed: ', state.user)
    },
    setAuthIsReady(state, payload) {
      state.authIsReady = payload
      console.log('Auth is ready')
    },
    setUserProfile(state, payload) {
      state.userProfile = payload
      console.log('User profile state changed: ', state.userProfile)
    },
    setCurrentRoute(state, payload) {
      state.currentRoute = payload
      console.log('Current route updated: ', state.currentRoute)
    }
  },
  actions: {
    async login(context, { email, password }) {
      console.log('Login Action')
      // async code
      const res = await signInWithEmailAndPassword(auth, email, password)
      if (res) {

        let docRef = doc(db, 'Users', res.user.uid)

        const unsub2 = onSnapshot(docRef, doc => {
          console.log(doc.data().role)
          if (doc.data().role != 'SUPER_ADMIN') {
            context.commit('setUser', res.user)
            context.commit('setUserProfile', { ...doc.data() })
          } else {
            console.log('Invalid User')
            signOut(auth)
          }

        }, (err) => {
          console.log(err.message)
        })

        watchEffect((onInvalidate) => {
          // unsubscribe from the realtime listner
          onInvalidate(() => unsub2())
        })
      } else {
        throw Error('Could not complete login')
      }


    },
    async logout(context) {
      console.log('Logout Action')

      // async code
      await signOut(auth)
      context.commit('setUser', null)
      context.commit('setUserProfile', null)
    },
    checkAuth({ commit }) {
      return new Promise((resolve) => {
        auth.onAuthStateChanged(user => {
          commit('setUser', user);
          commit('setAuthIsReady', true);
          resolve(user);
        });
      });
    },
  },
  modules: {
  }
})

// Waiting for auth and updating user state
const unsub = onAuthStateChanged(auth, (user) => {
  store.commit('setUser', user)


  if (user) {
    let docRef = doc(db, 'Users', user.uid)

    const unsub2 = onSnapshot(docRef, doc => {
      if (doc.data()) {
        store.commit('setUserProfile', { ...doc.data() })
      } else {
        // error.value = 'That docuemnt does not exsist'
      }
    }, (err) => {
      console.log(err.message)
    })

    watchEffect((onInvalidate) => {
      // unsubscribe from the realtime listner
      onInvalidate(() => unsub2())
    })

    store.commit('setAuthIsReady', true)
  }




  unsub()
})

export default store
