<template>
  <div class="modal" :class="{ 'is-active': showModal }">
    <div class="modal-background" @click="closeModal"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Create Customer Account</p>
        <button class="delete" aria-label="close" @click="closeModal"></button>
      </header>
      <section class="modal-card-body">
        <form @submit.prevent="submitForm">
          <div class="field">
            <label class="label">First Name</label>
            <input class="input" type="text" v-model="first_name" required />
          </div>
          <div class="field">
            <label class="label">Last Name</label>
            <input class="input" type="text" v-model="last_name" required />
          </div>
          <div class="field">
            <label class="label">Email</label>
            <input class="input" type="email" v-model="email" required />
          </div>
          <!-- <div class="field">
            <label class="label">Password</label>
            <input class="input" type="password" v-model="password" required />
          </div> -->
          <div class="field">
            <label class="label">Contact Number</label>
            <input class="input" type="tel" v-model="contact_number" required />
          </div>
          <div class="field">
            <label class="label">Primary Customer</label>
            <div class="select">
              <select v-model="selectedCustomer" required>
                <option
                  v-for="customer in customersList"
                  :key="customer.CustomerID"
                  :value="{
                    customer_id: customer.CustomerID,
                    customer_name: customer.CustomerName,
                    site_name: customer.SiteName,
                  }"
                >
                  {{ customer.CustomerName }} , {{ customer.SiteName }}
                </option>
              </select>
            </div>
          </div>
          <footer class="modal-card-foot">
            <button type="submit" class="button is-success">Save</button>
            <button type="button" class="button" @click="closeModal">
              Cancel
            </button>
          </footer>
        </form>
      </section>
    </div>
  </div>
</template>
  
<script setup>
import { ref, defineProps, defineEmits } from "vue";
const { showModal, customersList } = defineProps([
  "showModal",
  "customersList",
]);
const emit = defineEmits(["close", "save"]);

const first_name = ref("");
const last_name = ref("");
const email = ref("");
const password = ref("");
const contact_number = ref("");
const selectedCustomer = ref("");

const closeModal = () => {
  emit("close");
};

const submitForm = () => {
  const customer = {
    first_name: first_name.value,
    last_name: last_name.value,
    email: email.value,
    customer_logo: "URL",
    // password: password.value,
    contact_number: contact_number.value,
    primary_account: selectedCustomer.value.customer_id,
    role: "ACCOUNT_ADMIN",
    customers: selectedCustomer.value
      ? [
          {
            customer_id: selectedCustomer.value.customer_id,
            customer_name: selectedCustomer.value.customer_name,
            site_name: selectedCustomer.value.site_name,
          },
        ]
      : [],
  };
  emit("save", customer);

  // Clear the form variables
  first_name.value = "";
  last_name.value = "";
  email.value = "";
  // password.value = "";
  contact_number.value = "";
  selectedCustomer.value = null;

  closeModal();
};
</script>
  
  <style>
/* Additional styling if needed */
</style>
  